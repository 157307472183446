import * as React from 'react';

import IndexLayout from '../../layouts';

import Footer from '../../components/Footer/Footer';
import SEO from '../../components/SEO/SEO';

import IndustriesBusinessTypes from '../../components/Industries/IndustriesBusinessTypes/IndustriesBusinessTypes';
import IndustriesCarousel from '../../components/Industries/IndustriesCarousel/IndustriesCarousel';
import IndustriesIntegration from '../../components/Industries/IndustriesIntegration/IndustriesIntegration';
import IndustriesPaymentMethods from '../../components/Industries/IndustriesPaymentMethods/IndustriesPaymentMethods';
import ServicesFeatures from '../../components/Industries/Services/ServicesFeatures/ServicesFeatures';
import ServicesHero from '../../components/Industries/Services/ServicesHero/ServicesHero';
import ServicesSolutions from '../../components/Industries/Services/ServicesSolutions/ServicesSolutions';
import { LocaleTypes } from '../../data/locales';

interface ServicesPageProps {
  pathContext: {
    localeCode: LocaleTypes;
  };
  location: Location;
}

const ServicesPage: React.FC<ServicesPageProps> = ({ pathContext, location }) => {

  React.useEffect(() => {
    // Start of crazyegg code
    if( undefined !== window ){
      (function(w) {
        const s = document.createElement(`script`);
        s.src = `//script.crazyegg.com/pages/scripts/0110/8437.js`;
        s.async = true;
        const e = document.getElementsByTagName(`script`)[0];
        e.parentNode.insertBefore(s, e);
      })(window);
    }
    // End of crazyegg code
  })

  const content = () => (
    <>
      <SEO titleId="industries.basic_services.title" />

      <ServicesHero />

      <ServicesFeatures />
      <IndustriesBusinessTypes
        title="industries.basic_services.business_types.title"
        subtitle="industries.basic_services.business_types.subtitle"
        list={[
          'industries.basic_services.business_types.list.type_1',
          'industries.basic_services.business_types.list.type_2',
          'industries.basic_services.business_types.list.type_3',
          'industries.basic_services.business_types.list.type_4',
          'industries.basic_services.business_types.list.type_5',
        ]}
      />

      <ServicesSolutions />

      <IndustriesPaymentMethods/>

      <IndustriesIntegration />

      <IndustriesCarousel
        list={[
          'education',
          'ecommerce',
          'tourism',
        ]}
      />
      <Footer theme="primary" />
    </>
  );

  return <IndexLayout render={ content } locale={pathContext.localeCode} location={location} />
}

export default ServicesPage;

import React, { useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { graphql, useStaticQuery } from 'gatsby';
import ReactTooltip from 'react-tooltip';
import LocaleContext from '../../../contexts/LocaleContext';

import './styles.scss';

import slantImg from './assets/images/slant.svg';

const IndustriesPaymentMethods: React.FC = ({children}) => {
  const { allContentfulCountryPricing } = useStaticQuery(
    graphql`
      query {
        allContentfulCountryPricing(sort: {fields: code, order: ASC}) {
          edges {
            node {
              node_locale
              name
              code
              pricingMethods {
                pricingMethodType {
                  name
                  icon {
                    localFile {
                      publicURL
                    }
                  }
                }
                listTitle
                list {
                  name
                  img {
                    localFile {
                      publicURL
                    }
                  }
                }
              }
            }
          }
        },
      }
    `,
  );

  const locale = useContext(LocaleContext);

  const paymentMethods = allContentfulCountryPricing.edges.map((edge: any) => edge.node).filter((node: any) => node.node_locale === locale);

  const [selectedPaymentMethod, setPaymentMethod] = useState(paymentMethods[0]);

  return (
    <section className="py-layout-6 industries-payment-methods-section" style={{backgroundImage: `url(${slantImg})`}}>
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1">
            <h1 className="text-primary text-center">
              <FormattedMessage id="industries.payment_methods.title"/>
            </h1>

            <div className="industries-payment-methods mt-layout-4">
              <div className="industries-payment-methods__selector">
                <h5 className="text-dark-gray-2">
                  <FormattedMessage id="industries.payment_methods.selector_title"/>
                </h5>
                <select
                  className="select-input"
                  name="countrySelector"
                  id="country-selector"
                  onChange={e => setPaymentMethod(paymentMethods[e.currentTarget.value])}
                  defaultValue={0}
                >
                  {
                    paymentMethods.map((paymentMethod: any, index: number) =>
                      <option
                        key={paymentMethod.name}
                        value={index}
                      >
                        {paymentMethod.name}
                      </option>
                    )
                  }

                </select>
              </div>
              {
                selectedPaymentMethod.pricingMethods.map(
                  (pricingMethod: any) => (
                    <div className="industries-payment-methods__row" key={pricingMethod.pricingMethodType.name}>
                      <div className="industries-payment-methods__col">
                        <img className="pricing-method-icon mb-2" src={pricingMethod.pricingMethodType.icon.localFile.publicURL} alt="card icon" />
                        <h5 className="mb-md-0">{pricingMethod.pricingMethodType.name}</h5>
                      </div>
                      <div className="industries-payment-methods__col">
                        <h6 className="text-dark-gray-3">
                          {pricingMethod.listTitle}
                        </h6>

                        <div className="method-item-images">
                          {
                            pricingMethod
                            .list
                            .map((listElement: any) =>
                              <div key={`${selectedPaymentMethod.name}-${listElement.name}`}>
                              <img
                                src={listElement.img.localFile.publicURL}
                                alt={listElement.name}

                                data-tip="true"
                                data-for={`${selectedPaymentMethod.name}-${listElement.name}`}
                              />
                              <ReactTooltip
                                id={`${selectedPaymentMethod.name}-${listElement.name}`}
                                effect="float"
                              >
                                {listElement.name}
                              </ReactTooltip>
                            </div>
                            )
                          }
                        </div>
                      </div>
                    </div>
                  )
                )
              }


            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default IndustriesPaymentMethods;

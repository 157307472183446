import React from 'react';
import { FormattedMessage } from 'react-intl';
import { defaultFormatValues } from '../../../../data/defaultFormat';

import SalesButton from '../../../SalesButton/SalesButton';

import HeroImg from './assets/images/hero.inline.svg';

import './styles.scss';

const ServicesHero: React.FC = ({children}) => (
  <section className="hero overflow-visible">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-md-6 order-2 order-md-1">
          <h1 className="display-4 text-primary mt-layout-3 mt-md-0">
            <FormattedMessage id="industries.basic_services.hero.title" />
          </h1>
          <p className="lead mt-5">
            <FormattedMessage id="industries.basic_services.hero.subtitle"
              values={defaultFormatValues}
            />
          </p>
          <div className="button-responsive-row mt-2">
            <SalesButton positionTag="top">
              <FormattedMessage id="buttons.sales" />
            </SalesButton>
          </div>
        </div>
        <div className="col-md-6 offset-md-0 order-1 order-md-2 col-10 offset-1">
          <HeroImg className="img-fluid"/>
        </div>
      </div>
    </div>
  </section>
);

export default ServicesHero;
